import { default as indexo7FjlqWieSMeta } from "/opt/render/project/src/apps/frontend/pages/index.vue?macro=true";
import { default as bidsEGT4U8l50ZMeta } from "/opt/render/project/src/apps/frontend/pages/account/bids.vue?macro=true";
import { default as profile7l3D7CIEhNMeta } from "/opt/render/project/src/apps/frontend/pages/account/profile.vue?macro=true";
import { default as salesGqyGYq5EmrMeta } from "/opt/render/project/src/apps/frontend/pages/account/sales.vue?macro=true";
import { default as _91slug_93HyoRl6cTm4Meta } from "/opt/render/project/src/apps/frontend/pages/auction/[slug].vue?macro=true";
import { default as _91_46_46_46optionalParams_93GAuYBt56nZMeta } from "/opt/render/project/src/apps/frontend/pages/auctions/[localizedAssetType]/[...optionalParams].vue?macro=true";
import { default as cookie_45policyKtSfYJaJdeMeta } from "/opt/render/project/src/apps/frontend/pages/cookie-policy.vue?macro=true";
import { default as faqjLjP6Xq7LMMeta } from "/opt/render/project/src/apps/frontend/pages/faq.vue?macro=true";
import { default as password_45resetVf2vExmytjMeta } from "/opt/render/project/src/apps/frontend/pages/password-reset.vue?macro=true";
import { default as pricing_45buyer2hGrDrDoz3Meta } from "/opt/render/project/src/apps/frontend/pages/pricing-buyer.vue?macro=true";
import { default as pricing_45sellerStUodoLhdOMeta } from "/opt/render/project/src/apps/frontend/pages/pricing-seller.vue?macro=true";
import { default as privacy_45policy6IjMilWdgTMeta } from "/opt/render/project/src/apps/frontend/pages/privacy-policy.vue?macro=true";
import { default as confirmtfx7JtTocTMeta } from "/opt/render/project/src/apps/frontend/pages/signup/confirm.vue?macro=true";
import { default as indexDpTVRtyxWNMeta } from "/opt/render/project/src/apps/frontend/pages/signup/index.vue?macro=true";
import { default as terms_45and_45conditionszEnemuYX6KMeta } from "/opt/render/project/src/apps/frontend/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stubzFO8P7pzWFMeta } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzFO8P7pzWF } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/apps/frontend/pages/index.vue")
  },
  {
    name: "account-bids___de",
    path: "/de/account/bids",
    meta: bidsEGT4U8l50ZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/bids.vue")
  },
  {
    name: "account-bids___en",
    path: "/en/account/bids",
    meta: bidsEGT4U8l50ZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/bids.vue")
  },
  {
    name: "account-bids___es",
    path: "/es/account/bids",
    meta: bidsEGT4U8l50ZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/bids.vue")
  },
  {
    name: "account-bids___fr",
    path: "/fr/account/bids",
    meta: bidsEGT4U8l50ZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/bids.vue")
  },
  {
    name: "account-bids___it",
    path: "/it/account/bids",
    meta: bidsEGT4U8l50ZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/bids.vue")
  },
  {
    name: "account-bids___pl",
    path: "/pl/account/bids",
    meta: bidsEGT4U8l50ZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/bids.vue")
  },
  {
    name: "account-profile___de",
    path: "/de/account/profile",
    meta: profile7l3D7CIEhNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/profile.vue")
  },
  {
    name: "account-profile___en",
    path: "/en/account/profile",
    meta: profile7l3D7CIEhNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/profile.vue")
  },
  {
    name: "account-profile___es",
    path: "/es/account/profile",
    meta: profile7l3D7CIEhNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/profile.vue")
  },
  {
    name: "account-profile___fr",
    path: "/fr/account/profile",
    meta: profile7l3D7CIEhNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/profile.vue")
  },
  {
    name: "account-profile___it",
    path: "/it/account/profile",
    meta: profile7l3D7CIEhNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/profile.vue")
  },
  {
    name: "account-profile___pl",
    path: "/pl/account/profile",
    meta: profile7l3D7CIEhNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/profile.vue")
  },
  {
    name: "account-sales___de",
    path: "/de/account/sales",
    meta: salesGqyGYq5EmrMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/sales.vue")
  },
  {
    name: "account-sales___en",
    path: "/en/account/sales",
    meta: salesGqyGYq5EmrMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/sales.vue")
  },
  {
    name: "account-sales___es",
    path: "/es/account/sales",
    meta: salesGqyGYq5EmrMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/sales.vue")
  },
  {
    name: "account-sales___fr",
    path: "/fr/account/sales",
    meta: salesGqyGYq5EmrMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/sales.vue")
  },
  {
    name: "account-sales___it",
    path: "/it/account/sales",
    meta: salesGqyGYq5EmrMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/sales.vue")
  },
  {
    name: "account-sales___pl",
    path: "/pl/account/sales",
    meta: salesGqyGYq5EmrMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/sales.vue")
  },
  {
    name: "auction-slug___de",
    path: "/de/auktion/:slug()",
    meta: _91slug_93HyoRl6cTm4Meta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___en",
    path: "/en/auction/:slug()",
    meta: _91slug_93HyoRl6cTm4Meta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___es",
    path: "/es/subasta/:slug()",
    meta: _91slug_93HyoRl6cTm4Meta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___fr",
    path: "/fr/vente-aux-encheres/:slug()",
    meta: _91slug_93HyoRl6cTm4Meta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___it",
    path: "/it/asta/:slug()",
    meta: _91slug_93HyoRl6cTm4Meta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___pl",
    path: "/pl/aukcja/:slug()",
    meta: _91slug_93HyoRl6cTm4Meta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auction/[slug].vue")
  },
  {
    name: "auctions-localizedAssetType-optionalParams___de",
    path: "/de/auktionen/:localizedAssetType()/:optionalParams(.*)*",
    meta: _91_46_46_46optionalParams_93GAuYBt56nZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auctions/[localizedAssetType]/[...optionalParams].vue")
  },
  {
    name: "auctions-localizedAssetType-optionalParams___en",
    path: "/en/auctions/:localizedAssetType()/:optionalParams(.*)*",
    meta: _91_46_46_46optionalParams_93GAuYBt56nZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auctions/[localizedAssetType]/[...optionalParams].vue")
  },
  {
    name: "auctions-localizedAssetType-optionalParams___es",
    path: "/es/subastas/:localizedAssetType()/:optionalParams(.*)*",
    meta: _91_46_46_46optionalParams_93GAuYBt56nZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auctions/[localizedAssetType]/[...optionalParams].vue")
  },
  {
    name: "auctions-localizedAssetType-optionalParams___fr",
    path: "/fr/ventes-aux-encheres/:localizedAssetType()/:optionalParams(.*)*",
    meta: _91_46_46_46optionalParams_93GAuYBt56nZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auctions/[localizedAssetType]/[...optionalParams].vue")
  },
  {
    name: "auctions-localizedAssetType-optionalParams___it",
    path: "/it/aste/:localizedAssetType()/:optionalParams(.*)*",
    meta: _91_46_46_46optionalParams_93GAuYBt56nZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auctions/[localizedAssetType]/[...optionalParams].vue")
  },
  {
    name: "auctions-localizedAssetType-optionalParams___pl",
    path: "/pl/aukcje/:localizedAssetType()/:optionalParams(.*)*",
    meta: _91_46_46_46optionalParams_93GAuYBt56nZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auctions/[localizedAssetType]/[...optionalParams].vue")
  },
  {
    name: "cookie-policy___de",
    path: "/de/cookie-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/cookie-policy.vue")
  },
  {
    name: "cookie-policy___en",
    path: "/en/cookie-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/cookie-policy.vue")
  },
  {
    name: "cookie-policy___es",
    path: "/es/cookie-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/cookie-policy.vue")
  },
  {
    name: "cookie-policy___fr",
    path: "/fr/cookie-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/cookie-policy.vue")
  },
  {
    name: "cookie-policy___it",
    path: "/it/cookie-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/cookie-policy.vue")
  },
  {
    name: "cookie-policy___pl",
    path: "/pl/cookie-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/cookie-policy.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/opt/render/project/src/apps/frontend/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/opt/render/project/src/apps/frontend/pages/faq.vue")
  },
  {
    name: "faq___es",
    path: "/es/faq",
    component: () => import("/opt/render/project/src/apps/frontend/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/opt/render/project/src/apps/frontend/pages/faq.vue")
  },
  {
    name: "faq___it",
    path: "/it/faq",
    component: () => import("/opt/render/project/src/apps/frontend/pages/faq.vue")
  },
  {
    name: "faq___pl",
    path: "/pl/faq",
    component: () => import("/opt/render/project/src/apps/frontend/pages/faq.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/opt/render/project/src/apps/frontend/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/render/project/src/apps/frontend/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/opt/render/project/src/apps/frontend/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/opt/render/project/src/apps/frontend/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/opt/render/project/src/apps/frontend/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/opt/render/project/src/apps/frontend/pages/index.vue")
  },
  {
    name: "password-reset___de",
    path: "/de/password-reset",
    meta: password_45resetVf2vExmytjMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/password-reset.vue")
  },
  {
    name: "password-reset___en",
    path: "/en/password-reset",
    meta: password_45resetVf2vExmytjMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/password-reset.vue")
  },
  {
    name: "password-reset___es",
    path: "/es/password-reset",
    meta: password_45resetVf2vExmytjMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/password-reset.vue")
  },
  {
    name: "password-reset___fr",
    path: "/fr/password-reset",
    meta: password_45resetVf2vExmytjMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/password-reset.vue")
  },
  {
    name: "password-reset___it",
    path: "/it/password-reset",
    meta: password_45resetVf2vExmytjMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/password-reset.vue")
  },
  {
    name: "password-reset___pl",
    path: "/pl/password-reset",
    meta: password_45resetVf2vExmytjMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/password-reset.vue")
  },
  {
    name: "pricing-buyer___de",
    path: "/de/pricing-buyer",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-buyer.vue")
  },
  {
    name: "pricing-buyer___en",
    path: "/en/pricing-buyer",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-buyer.vue")
  },
  {
    name: "pricing-buyer___es",
    path: "/es/pricing-buyer",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-buyer.vue")
  },
  {
    name: "pricing-buyer___fr",
    path: "/fr/pricing-buyer",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-buyer.vue")
  },
  {
    name: "pricing-buyer___it",
    path: "/it/pricing-buyer",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-buyer.vue")
  },
  {
    name: "pricing-buyer___pl",
    path: "/pl/pricing-buyer",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-buyer.vue")
  },
  {
    name: "pricing-seller___de",
    path: "/de/pricing-seller",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-seller.vue")
  },
  {
    name: "pricing-seller___en",
    path: "/en/pricing-seller",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-seller.vue")
  },
  {
    name: "pricing-seller___es",
    path: "/es/pricing-seller",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-seller.vue")
  },
  {
    name: "pricing-seller___fr",
    path: "/fr/pricing-seller",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-seller.vue")
  },
  {
    name: "pricing-seller___it",
    path: "/it/pricing-seller",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-seller.vue")
  },
  {
    name: "pricing-seller___pl",
    path: "/pl/pricing-seller",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-seller.vue")
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/privacy-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___it",
    path: "/it/privacy-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___pl",
    path: "/pl/privacy-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/privacy-policy.vue")
  },
  {
    name: "signup-confirm___de",
    path: "/de/signup/confirm",
    meta: confirmtfx7JtTocTMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/confirm.vue")
  },
  {
    name: "signup-confirm___en",
    path: "/en/signup/confirm",
    meta: confirmtfx7JtTocTMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/confirm.vue")
  },
  {
    name: "signup-confirm___es",
    path: "/es/signup/confirm",
    meta: confirmtfx7JtTocTMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/confirm.vue")
  },
  {
    name: "signup-confirm___fr",
    path: "/fr/signup/confirm",
    meta: confirmtfx7JtTocTMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/confirm.vue")
  },
  {
    name: "signup-confirm___it",
    path: "/it/signup/confirm",
    meta: confirmtfx7JtTocTMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/confirm.vue")
  },
  {
    name: "signup-confirm___pl",
    path: "/pl/signup/confirm",
    meta: confirmtfx7JtTocTMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/confirm.vue")
  },
  {
    name: "signup___de",
    path: "/de/signup",
    meta: indexDpTVRtyxWNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/index.vue")
  },
  {
    name: "signup___en",
    path: "/en/signup",
    meta: indexDpTVRtyxWNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/index.vue")
  },
  {
    name: "signup___es",
    path: "/es/signup",
    meta: indexDpTVRtyxWNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/index.vue")
  },
  {
    name: "signup___fr",
    path: "/fr/signup",
    meta: indexDpTVRtyxWNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/index.vue")
  },
  {
    name: "signup___it",
    path: "/it/signup",
    meta: indexDpTVRtyxWNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/index.vue")
  },
  {
    name: "signup___pl",
    path: "/pl/signup",
    meta: indexDpTVRtyxWNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/index.vue")
  },
  {
    name: "terms-and-conditions___de",
    path: "/de/terms-and-conditions",
    component: () => import("/opt/render/project/src/apps/frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/opt/render/project/src/apps/frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___es",
    path: "/es/terms-and-conditions",
    component: () => import("/opt/render/project/src/apps/frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___fr",
    path: "/fr/terms-and-conditions",
    component: () => import("/opt/render/project/src/apps/frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___it",
    path: "/it/terms-and-conditions",
    component: () => import("/opt/render/project/src/apps/frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___pl",
    path: "/pl/terms-and-conditions",
    component: () => import("/opt/render/project/src/apps/frontend/pages/terms-and-conditions.vue")
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en-GB-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/es-ES-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/fr-FR-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/it-IT-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/pl-PL-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  }
]