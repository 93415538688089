import revive_payload_client_YTGlmQWWT5 from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/render/project/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fWYXZjzQhM from "/opt/render/project/src/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_VTarsGAzgb from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_2g9zaaxwFs from "/opt/render/project/src/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_KxR4LNkOik from "/opt/render/project/src/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import plugin_kpnqASteq8 from "/opt/render/project/src/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_jXOoAsp0hm from "/opt/render/project/src/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import slideovers_2NIjZ3dBbn from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_FeYNAF2WLE from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_mI6xh0VV9a from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_x7So6BU7bH from "/opt/render/project/src/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_8brYH9QEed from "/opt/render/project/src/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_lCxu0ZmxIx from "/opt/render/project/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/opt/render/project/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import error_handler_kEP5FliEXj from "/opt/render/project/src/apps/frontend/plugins/error-handler.ts";
import sessionExpiration_0kmdyyoC1R from "/opt/render/project/src/apps/frontend/plugins/sessionExpiration.ts";
import swiper_eteSCvuYkd from "/opt/render/project/src/apps/frontend/plugins/swiper.ts";
import timeSync_client_wAmKfexdIs from "/opt/render/project/src/apps/frontend/plugins/timeSync.client.ts";
import vueQuery_HbXfDZmQp2 from "/opt/render/project/src/apps/frontend/plugins/vueQuery.ts";
import wsAuctions_client_uUFKFNaDbZ from "/opt/render/project/src/apps/frontend/plugins/wsAuctions.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_fWYXZjzQhM,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  titles_2g9zaaxwFs,
  defaultsWaitI18n_KxR4LNkOik,
  plugin_kpnqASteq8,
  plugin_jXOoAsp0hm,
  slideovers_2NIjZ3dBbn,
  modals_FeYNAF2WLE,
  colors_mI6xh0VV9a,
  plugin_client_x7So6BU7bH,
  plugin_8brYH9QEed,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  error_handler_kEP5FliEXj,
  sessionExpiration_0kmdyyoC1R,
  swiper_eteSCvuYkd,
  timeSync_client_wAmKfexdIs,
  vueQuery_HbXfDZmQp2,
  wsAuctions_client_uUFKFNaDbZ
]