<script setup lang="ts">
import type { MetaAttrs } from '@nuxtjs/i18n';

import { Locale } from '~/i18n/i18nConfig';

const i18nHead = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true,
});

const filterAlternateLinks = (l: MetaAttrs): boolean => l.rel !== 'alternate' || Object.values(Locale).includes(l.hreflang);

useHead({
    titleTemplate: title => {
        return title ? `${title} - Fleequid` : 'Fleequid';
    },
    htmlAttrs: {
        lang: i18nHead.value.htmlAttrs!.lang,
    },
    link: [...(i18nHead.value.link || []).filter(filterAlternateLinks)],
    meta: [...(i18nHead.value.meta || [])],
});
useServerSeoMeta({
    ogImage: {
        url: '/fleequid.jpg',
        type: 'image/jpeg',
        width: '1200px',
        height: '630px',
        alt: 'Fleequid',
    },
    twitterCard: 'summary_large_image',
});
</script>
<template>
    <NuxtLoadingIndicator
        color="repeating-linear-gradient(to right,rgb(var(--color-primary-200)) 0%,rgb(var(--color-primary-500)) 50%,rgb(var(--color-primary-900)) 100%)"
    />
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>

    <UNotifications />
    <UModals />
</template>
