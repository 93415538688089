
export enum BackendErrorCode {
  NotValidEmail= 'NotValidEmail',
  WeakPassword= 'WeakPassword',
  InvalidEnumValue= 'InvalidEnumValue',
  InvalidUUID= 'InvalidUUID',
  InvalidJWT= 'InvalidJWT',
  InvalidDate= 'InvalidDate',
  InvalidBoolean= 'InvalidBoolean',
  InvalidLength= 'InvalidLength',
  IsNotTrue= 'IsNotTrue',
  NotAString= 'NotAString',
  EmptyString= 'EmptyString',
  InvalidPhoneNumber= 'InvalidPhoneNumber',
  NumberTooSmallThanRelated= 'NumberTooSmallThanRelated',
  NumberTooBigThanRelated= 'NumberTooBigThanRelated',
  AuctionStartDateWeekend= 'AuctionStartDateWeekend',
  NotAWorkingDay= 'NotAWorkingDay',
  PropertyShouldNotExists= 'PropertyShouldNotExists',
  InvalidBidAmount= 'InvalidBidAmount',
  InvalidPropertyByRules= 'InvalidPropertyByRules',
  InvalidBusDossierImageName= 'InvalidBusDossierImageName',
  InvalidBusDossierVideoName= 'InvalidBusDossierVideoName',
  InvalidBusDossierDamageImageName= 'InvalidBusDossierDamageImageName',
  PrismaError= 'PrismaError',
  EntityNotFound= 'EntityNotFound',
  EntityAlreadyExists= 'EntityAlreadyExists',
  PasswordResetEmailNotExists= 'PasswordResetEmailNotExists',
  SignupUserAlreadyExists= 'SignupUserAlreadyExists',
  EmailChangeWrongPassword= 'EmailChangeWrongPassword',
  PasswordDoNotMatch= 'PasswordDoNotMatch',
  AuthWrongCredentials= 'AuthWrongCredentials',
  AuthNoUserByEmail= 'AuthNoUserByEmail',
  AuthNoAdminByEmail= 'AuthNoAdminByEmail',
  AuthWrongPassword= 'AuthWrongPassword',
  AuthTokenNotSubmitted= 'AuthTokenNotSubmitted',
  AuthTokenNotValid= 'AuthTokenNotValid',
  AuthNotEnoughPermission= 'AuthNotEnoughPermission',
  AuthTokenExpired= 'AuthTokenExpired',
  AuthTokenMalformed= 'AuthTokenMalformed',
  AuthEmailNotVerified= 'AuthEmailNotVerified',
  AuthAuthenticationVersionMismatch= 'AuthAuthenticationVersionMismatch',
  AuthRolesNotDefined= 'AuthRolesNotDefined',
  UserIsBanned= 'UserIsBanned',
  AdminCannotBeBanned= 'AdminCannotBeBanned',
  BailNotAuthorized= 'BailNotAuthorized',
  UnknownError= 'UnknownError',
  UserHasNoCompany= 'UserHasNoCompany',
  UploadFileError= 'UploadFileError',
  FileNotUploaded= 'FileNotUploaded',
  ErrorDuringClearingCache= 'ErrorDuringClearingCache',
  InvalidCaptcha= 'InvalidCaptcha',
  MissingCaptcha= 'MissingCaptcha',
  AuctionExpired= 'AuctionExpired',
  AuctionNotRunning= 'AuctionNotRunning',
  AuctionNotClosed= 'AuctionNotClosed',
  AuctionClearingPriceNotSettable= 'AuctionClearingPriceNotSettable',
  UserCompanyEqualsToSellerCompany= 'UserCompanyEqualsToSellerCompany',
  ToBeScrapedDoesNotMatch= 'ToBeScrapedDoesNotMatch',
  UserHasNoBail= 'UserHasNoBail',
  ReservePriceAlreadyReached= 'ReservePriceAlreadyReached',
  AuctionInvalidMainAssetId= 'AuctionInvalidMainAssetId',
  AuctionMainAssetIdRequired= 'AuctionMainAssetIdRequired',
  AuctionAssetCongruenceMismatch= 'AuctionAssetCongruenceMismatch',
  AuctionUpdateDenied= 'AuctionUpdateDenied',
  InvalidUrlKeyValue= 'InvalidUrlKeyValue',
  CantBidUserCompanyEqSellerCompany= 'CantBidUserCompanyEqSellerCompany',
  CantBidScrapAuctionNotScrapyardUser= 'CantBidScrapAuctionNotScrapyardUser',
  CantBidAuctionIsNotRunning= 'CantBidAuctionIsNotRunning',
  CantBidUserNoBail= 'CantBidUserNoBail',
  CantBidUserIsMaxBidder= 'CantBidUserIsMaxBidder',
  CantBidUnapprovedCompany= 'CantBidUnapprovedCompany',
  CantBidUserWithAutoBidActive= 'CantBidUserWithAutoBidActive',
  InvalidAutoBidAmount= 'InvalidAutoBidAmount',
  InvalidAutoBidId= 'InvalidAutoBidId',
  AutoBidNotCreated= 'AutoBidNotCreated',
  CantAutoBid= 'CantAutoBid',
  MissingBidAmountOrAutoBidAmount= 'MissingBidAmountOrAutoBidAmount',
  BidNotCreated= 'BidNotCreated',
  InvalidUserAutoBid= 'InvalidUserAutoBid',
  InvalidAuctionAutoBid= 'InvalidAuctionAutoBid',
  PaymentProviderError= 'PaymentProviderError',
  BusDossierPublishingFailed= 'BusDossierPublishingFailed',
  WinningBidNotFound= 'WinningBidNotFound',
  CountryCodeNotFound= 'CountryCodeNotFound',
  InvalidTaxCodeCombination= 'InvalidTaxCodeCombination',
  VatNumberNotFound= 'VatNumberNotFound',

}