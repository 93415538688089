import { ofetch } from 'ofetch';

export default defineNuxtPlugin(() => {
    // COMPOSABLES
    const { isSessionExpired, sessionExpiredHandler } = useSessionExpiration();

    // https://stackoverflow.com/questions/75434573/redefine-fetch-in-nuxt3-with-global-onrequest-handler
    globalThis.$fetch = ofetch.create({
        onResponseError({ response }) {
            if (isSessionExpired(response._data?.error?.code)) {
                sessionExpiredHandler();
            }
        },
    });
});
