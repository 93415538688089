export enum Locale {
    DE = 'de',
    EN = 'en',
    ES = 'es',
    FR = 'fr',
    IT = 'it',
    PL = 'pl',
}

const LOCALE_ISO_MAP = {
    [Locale.DE]: 'de-DE',
    [Locale.EN]: 'en-GB',
    [Locale.ES]: 'es-ES',
    [Locale.FR]: 'fr-FR',
    [Locale.IT]: 'it-IT',
    [Locale.PL]: 'pl-PL',
};

export const locales = Object.values(Locale).map(code => ({ code, language: LOCALE_ISO_MAP[code] }));

export const defaultLocale = Locale.EN;

export const pages = {
    'auction/[slug]': {
        de: '/auktion/[slug]',
        en: '/auction/[slug]',
        es: '/subasta/[slug]',
        fr: '/vente-aux-encheres/[slug]',
        it: '/asta/[slug]',
        pl: '/aukcja/[slug]',
    },
    'auctions/[localizedAssetType]/[...optionalParams]': {
        de: '/auktionen/[localizedAssetType]/[...optionalParams]',
        en: '/auctions/[localizedAssetType]/[...optionalParams]',
        es: '/subastas/[localizedAssetType]/[...optionalParams]',
        fr: '/ventes-aux-encheres/[localizedAssetType]/[...optionalParams]',
        it: '/aste/[localizedAssetType]/[...optionalParams]',
        pl: '/aukcje/[localizedAssetType]/[...optionalParams]',
    },
};
