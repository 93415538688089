import type { Ref } from 'vue';

// eslint-disable-next-line regex/invalid
import { LoginModal, RequestPasswordResetModal, SessionExpiredModal } from '#components';

enum ModalType {
    LOGIN = 'login',
    PASSWORD_RESET = 'password_reset',
    SESSION_EXPIRED = 'session_expired',
}

type UseModalsReturn = {
    openLoginModal: (props?: any) => void;
    closeLoginModal: () => void;
    isLoginModalOpen: Ref<boolean>;

    openRequestPasswordResetModal: () => void;
    closeRequestPasswordResetModal: () => void;
    isRequestPasswordResetModalOpen: Ref<boolean>;

    openSessionExpiredModal: () => void;
    closeSessionExpiredModal: () => void;
    isSessionExpiredModalOpen: Ref<boolean>;
};

export const useModals = (): UseModalsReturn => {
    const openedModal = useState<ModalType | null>(() => null);
    const modal = useModal();
    const { isLoggedIn } = useAuthUtils();

    // UTILS
    const open = async (modalType: ModalType, modalProps = {}): Promise<void> => {
        if (openedModal.value && openedModal.value !== modalType) {
            await close(openedModal.value);
        }

        const component = {
            [ModalType.LOGIN]: LoginModal,
            [ModalType.PASSWORD_RESET]: RequestPasswordResetModal,
            [ModalType.SESSION_EXPIRED]: SessionExpiredModal,
        }[modalType];

        if (component !== null) {
            openedModal.value = modalType;
            modal.open(component, modalProps);
        }
    };

    const close = async (modalType: ModalType): Promise<void> => {
        if (openedModal.value !== modalType) {
            return;
        }

        await modal.close();
        openedModal.value = null;
        // The timeout ensures that the transition is completed and useModal reset() has been executed.
        // This is necessary to open a new modal which replaces the current.
        await new Promise(resolve => setTimeout(resolve, 300));
    };

    return {
        openLoginModal: (props?: any) => !isLoggedIn.value && open(ModalType.LOGIN, props),
        closeLoginModal: () => close(ModalType.LOGIN),
        isLoginModalOpen: computed<boolean>(() => modal.isOpen.value && openedModal.value === ModalType.LOGIN),

        openRequestPasswordResetModal: () => !isLoggedIn.value && open(ModalType.PASSWORD_RESET),
        closeRequestPasswordResetModal: () => close(ModalType.PASSWORD_RESET),
        isRequestPasswordResetModalOpen: computed<boolean>(() => modal.isOpen.value && openedModal.value === ModalType.PASSWORD_RESET),

        openSessionExpiredModal: () => open(ModalType.SESSION_EXPIRED),
        closeSessionExpiredModal: () => close(ModalType.SESSION_EXPIRED),
        isSessionExpiredModalOpen: computed<boolean>(() => modal.isOpen.value && openedModal.value === ModalType.SESSION_EXPIRED),
    };
};
