import type { NotificationAction } from '#ui/types';
import lodashOmit from 'lodash-es/omit';

import type { BackendErrorMessageEntity } from '~/apiClient';
import { NotificationTimeout } from '~/types/notifications.type';

export type NotifyParams = {
    title: string;
    description?: string;
    icon?: string;
    timeout?: NotificationTimeout;
    clickHandler?: (...args: any[]) => void;
    actions?: NotificationAction[];
};

export type NotifyBackendErrorParams = Omit<NotifyParams, 'description' | 'title'> & {
    error: BackendErrorMessageEntity;
    customMessages?: ErrorCodesToMessages;
};

export type UseNotificationReturn = {
    notifyGenericError: () => void;
    notifyError: (params: NotifyParams) => void;
    notifyBackendError: (params: NotifyBackendErrorParams) => void;
    notifySuccess: (params: NotifyParams) => void;
    notifyInfo: (params: NotifyParams) => void;
    notifyWarning: (params: NotifyParams) => void;
};

export const useNotification = (): UseNotificationReturn => {
    const { isSessionExpired } = useSessionExpiration();
    const { getErrorDescription } = useBackendErrors();
    const { tt } = useTypedI18n();
    const toast = useToast();

    const notifyError: (params: NotifyParams) => void = ({ title, description, timeout, clickHandler, actions }) => {
        toast.add({
            title,
            description,
            color: 'red',
            icon: 'i-heroicons-x-circle-16-solid',
            timeout: timeout ?? NotificationTimeout.Standard,
            click: clickHandler,
            actions: actions,
        });
    };

    return {
        notifyGenericError: (): void => notifyError({ title: tt('notifications.error'), description: tt('notifications.genericError') }),

        notifyError,

        notifyBackendError: (params): void => {
            if (isSessionExpired(params.error.code)) {
                return;
            }

            notifyError({
                ...lodashOmit(params, 'customMessages'),
                title: tt('notifications.error'),
                description: getErrorDescription(params.error, params.customMessages),
            });
        },

        notifySuccess: ({ title, description, icon = 'i-mdi-celebration', timeout, clickHandler, actions }): void => {
            toast.add({
                title,
                description,
                color: 'green',
                icon,
                timeout: timeout ?? NotificationTimeout.Standard,
                click: clickHandler,
                actions,
            });
        },

        notifyInfo: ({ title, description, icon = 'i-mdi-notifications', timeout, clickHandler, actions }): void => {
            toast.add({
                title,
                description,
                color: 'primary',
                icon,
                timeout: timeout ?? NotificationTimeout.Standard,
                click: clickHandler,
                actions,
            });
        },

        notifyWarning: ({ title, description, icon = 'i-mdi-error', timeout, clickHandler, actions }): void => {
            toast.add({
                title,
                description,
                color: 'fl-secondary',
                icon,
                timeout: timeout ?? NotificationTimeout.Standard,
                click: clickHandler,
                actions,
            });
        },
    };
};
