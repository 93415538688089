import {
  useQuery
} from '@tanstack/vue-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  unref
} from 'vue'
import type {
  GetServerTimeResponseDto
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiGetServerTime = (
    
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      
      
      return fetchApiInstance<GetServerTimeResponseDto>(
      {url: `/timeSync/serverTime`, method: 'GET'
    },
      options);
    }
  

export const getApiGetServerTimeQueryKey = () => {
    return ['timeSync','serverTime'] as const;
    }

    
export const getApiGetServerTimeQueryOptions = <TData = Awaited<ReturnType<typeof apiGetServerTime>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetServerTime>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetServerTimeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetServerTime>>> = () => apiGetServerTime(requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetServerTime>>, TError, TData> 
}

export type ApiGetServerTimeQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetServerTime>>>
export type ApiGetServerTimeQueryError = ErrorType<unknown>



export function useApiGetServerTime<TData = Awaited<ReturnType<typeof apiGetServerTime>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetServerTime>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetServerTimeQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



