<script setup lang="ts">
import type { AlertAction } from '#ui/types';
import { z } from 'zod';

import type { UInput } from '#components';
import { BackendErrorCode } from '~/apiClient';

// EMITS
const emit = defineEmits<{
    success: [];
}>();

// CONSTANTS
const formGroupUi = {
    container: 'pb-6',
    error: 'absolute',
};

// COMPOSABLES
const { tt } = useTypedI18n();
const { login, isLoggedIn } = useAuthUtils();
const { closeLoginModal, openRequestPasswordResetModal } = useModals();
const { getErrorDescription } = useBackendErrors();
const { isMobile, isLgOrGreater } = useResponsive();
const localePath = useLocalePath();

// DATA
const formSchema = z.object({
    email: zodRequiredString(tt('validation.msg.required', { field: tt('login.email') })).email(
        tt('validation.msg.invalid', { field: tt('login.email') })
    ),
    password: zodRequiredString(tt('validation.msg.required', { field: tt('login.password') })),
});

type FormData = z.infer<typeof formSchema>;

const formData: FormData = reactive({
    email: '',
    password: '',
});

const firstInputRef = ref<typeof UInput>();

const isSubmitting = ref(false);
const loginErrorMessage = ref<string | null>(null);
const errorCode = ref<BackendErrorCode | null>(null);

// METHODS
const onSubmit = async (): Promise<void> => {
    isSubmitting.value = true;
    loginErrorMessage.value = null;
    errorCode.value = null;

    try {
        await login(formData.email, formData.password);

        if (isLoggedIn) {
            closeLoginModal();
            emit('success');
        }
    } catch (error) {
        if (isFetchError(error) && isBackendError(error.data)) {
            loginErrorMessage.value = getErrorDescription(error.data.error);
            errorCode.value = error.data.error.code;
        } else {
            loginErrorMessage.value = tt('notifications.genericError');
        }
    }

    isSubmitting.value = false;
};

const alertActions = computed<AlertAction[]>(() => [
    ...insertIf(errorCode.value === BackendErrorCode.AuthNoUserByEmail, {
        label: tt('login.signupNow'),
        to: localePath({ name: 'signup' }),
        click: closeLoginModal,
    }),
]);

const onForgotPasswordClick = (): void => {
    openRequestPasswordResetModal();
};

// LIFECYCLE
onMounted(() => {
    nextTick(() => {
        isLgOrGreater.value && firstInputRef.value && firstInputRef.value.$refs.input.focus();
    });
});
</script>

<template>
    <UModal :ui="{ width: 'md:max-w-md' }" :fullscreen="isMobile" :transition="!isMobile" @close="closeLoginModal">
        <div class="flex h-full flex-col justify-center">
            <UButton class="absolute right-6 top-6 p-1" variant="ghost" @click="closeLoginModal">
                <UIcon name="i-heroicons-x-mark-20-solid" class="text-2xl text-black" />
            </UButton>
            <div class="p-4 md:p-10">
                <UForm class="flex flex-col items-center" :state="formData" :schema="formSchema" @submit="onSubmit">
                    <p class="text-center text-4xl font-semibold">{{ tt('common.loginTitle') }}</p>
                    <p class="mb-4 mt-4 w-4/5 text-center text-gray-600">{{ tt('login.formSubtitle') }}</p>

                    <p class="text mb-10 mt-4 flex w-4/5 flex-wrap justify-center gap-1 text-center text-gray-600">
                        <span>{{ tt('login.unregistered') }}</span>
                        <NuxtLinkLocale to="signup" class="text-primary underline" @click="closeLoginModal">
                            {{ tt('login.signupNow') }}
                        </NuxtLinkLocale>
                    </p>
                    <UFormGroup class="mb-2 w-full" :label="tt('login.email')" name="email" required :ui="formGroupUi">
                        <UInput ref="firstInputRef" v-model.trim="formData.email" autocomplete="email" :disabled="isSubmitting" />
                    </UFormGroup>

                    <UFormGroup class="w-full" :label="tt('login.password')" name="password" required :ui="formGroupUi">
                        <BasePasswordInput v-model="formData.password" autocomplete="current-password" :disabled="isSubmitting" />
                    </UFormGroup>

                    <button type="button" class="w-full text-left text-base text-black underline" @click.prevent="onForgotPasswordClick">
                        {{ tt('login.forgotPassword') }}
                    </button>

                    <BaseAlert v-if="loginErrorMessage" class="mt-8" :title="loginErrorMessage" type="error" :actions="alertActions" />

                    <UButton class="mt-10" block :label="tt('common.login')" type="submit" size="xl" :loading="isSubmitting" />
                </UForm>
            </div>
        </div>
    </UModal>
</template>
